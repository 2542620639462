// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@350;600;700&display=swap");
// @import url("https://use.typekit.net/teu1hmd.css");
@import "~antd/dist/antd.less";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700&family=Inter:wght@300;400;500;600;700&display=swap');

#root,
.app {
  min-height: 100vh;
  width: 100%;
  background: #040d21;

  // overflow: auto;
  display: flex;
}

body{
  overflow: overlay;
  width: 100vw;
}

html, body {
  min-height: 100vh;
  height: fit-content;
  position: relative;
  background: #040d21 !important;

}

//define font family
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/woff/SVN-Poppins-Light.woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/woff/SVN-Poppins-Medium.woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/woff/SVN-Poppins-Regular.woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/woff/SVN-Poppins-Bold.woff');
  font-weight: 700;
  font-style: normal;
}

@primary-color: #157dc5;
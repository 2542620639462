.square {
  width: 18px !important;
  height: 18px !important;
  margin: 0px;
  background-color: #b05841;
  margin: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px;
  color: white;
  font-size: 12px;
  &:hover,
  &:focus {
    transition: none !important;
    color: white !important;
  }
}

.square-active {
  width: 18px !important;
  height: 18px !important;
  margin: 0px;
  background-color: yellow !important;
  margin: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px;
  color: black;
  &:hover,
  &:focus {
    background: yellow !important;
    background-color: yellow !important;
    transition: none !important;
    color: black !important;
  }
}

.stage-row {
  margin: 1px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.stage-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.stage-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.seat-container {
  border: 1px solid #b05841;
  padding: 10px;
  border-radius: 10px;
}

.seat-row {
  display: flex;
  flex-direction: row;
}

.main-stage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #aa5c35;
}

.main-stage-rec {
  width: 400px;
  height: 150px;
  border-radius: 20px;
  background-color: #aa5c35;
}

.stage {
  border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.05);
  background-color: rgba(0, 0, 0, 0.1);
  margin: 12px;
  padding: 6px;
}

.react-transform-wrapper {
  width: 100%;
}

.seat-code {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  margin-right: 5px;
  background-color: #b05841;
  &.sun {
    background-color: #d7a578;
  }
  &.gift {
    background-color: #8e7754;
  }

  &.moon {
    background-color: rgb(60, 111, 114);
  }

  &.star {
    background-color: #aa4e36;
  }

  &.universe {
    background-color: #bd7d4c;
  }
}
.seat-type-container {
  margin: 6px;
  padding: 12px;
}

.home-card {
  background-color: rgba($color: #000000, $alpha: 0.1);
  width: 100%;
  max-width: 240px;
  height: 160px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
  cursor: pointer;
  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    padding: 12px;
  }
  padding: 12px;
}

.home {
  .placeholder {
    width: 100%;
    max-width: 360px;
    object-fit: contain;
    padding: 24px;
    img {
      width: 100%;
      border-radius: 16px;
    }
  }
  .program {
    cursor: pointer;

    display: flex;
    align-items: center;
    flex-direction: column;

    &:hover {
      .home-card {
        background-color: rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
  color: white;
}

@media only screen and (max-width: 768px) {
  .home {
    margin: 4px;
    .home-card {
      padding: 4px;
    }
    .placeholder {
      padding: 4px;
    }
  }
}

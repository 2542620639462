.hayfest-checkout {
  height: 100%;
  .text,
  a {
    color: black;
  }
  .container {
    background-color: #ffe818 !important;
    z-index: 999;
    padding: 24px;
  }
  .info-title {
    font-family: "Inconsolata";
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  }
  .confirm-btn {
    background-color: black;
    color: #ffe818;
    border-radius: 0px;
    height: 56px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    width: 100%;
  }
  .dvd {
    height: 100%;
    width: 2px;
    background-color: black;
    margin-left: 12px;
    margin-right: 12px;
  }
}

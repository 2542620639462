.common-input,
.common-input.ant-input-number {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #202637;
  color: white;
}
.common-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #202637;
  color: white;
}
.common-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.common-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #202637;
  color: white;
}

.common-form {
  label {
    color: white !important;
  }
}
.ant-input-lg,
.ant-btn-lg,
.ant-picker-large .ant-picker-input > input {
  font-size: 14px !important;
}
.ant-input-prefix {
  svg {
    width: 14px;
  }
}

.banner-container {
  position: relative;
  img {
    width: 100%;
  }
  .coming::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .coming {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.intro {
  .info-text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;
  }
}

.price-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #f1f3ec;
  padding: 24px;
  width: 90%;
  .title {
    font-weight: bold;
    font-size: 18px;
  }
  .seat-class {
    font-weight: bold;
    font-size: 18px;
    font-family: "Inconsolata";
  }
  .price {
    font-weight: bold;
    font-size: 18px;
    margin-top: 12px;
  }
  .description {
    margin-top: 12px;
  }
  .btn {
    background-color: #ffe818;
    width: 100%;
    font-weight: bold;
    font-family: "Inconsolata";
    text-transform: uppercase;
    font-size: 16px;
    height: 42px;
    border: none;
    align-self: flex-end;
  }
}

.line-up {
  img {
    width: 100%;
  }
}

.follow {
  font-size: 14px;
}

.hayfest {
  width: 100%;
  background-color: #ffe818;
  font-family: "Inter";
  .header-row {
    .ant-col {
      height: 100%;
    }
    height: 100%;
    .place {
      font-size: 30px;
    }
    .date {
      font-size: 30px;
      font-weight: bold;
    }
  }
  .menu-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-title {
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      margin-left: 6px;
    }
    .menu-icon {
      height: 32px;
    }
  }
}
.description {
  white-space: pre-line;
  width: 100%;
}

.section-title {
  color: white;
  font-size: 28px;
  font-weight: bold;
}

.booking-btn {
  position: absolute;
  bottom: 36px;
  display: flex;
  z-index: 99;
}

.event-banner {
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.event-banner-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.2) 80%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.9) 95%,
    rgba(0, 0, 0, 1) 100%
  );
}

.event-banner {
  .bottom {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
}

.event-card {
  cursor: pointer;
}

.swiper {
  width: 100%;
  height: 100%;
}

// .swiper-slide {
//   text-align: center;
//   font-size: 18px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   -webkit-align-items: center;
//   align-items: center;
// }

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper-button-prev {
  z-index: 6;
  // margin-top: 10px;
  position: absolute;
  top: 20px !important;
  bottom: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: calc(100vw / 8) !important;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: #ffe818 !important;
  justify-content: start !important;
  &::after {
    margin-left: 10px;
  }
}

.swiper-button-next {
  z-index: 6;
  // margin-top: 10px;
  position: absolute;
  top: 20px !important;
  bottom: 0 !important;
  right: 0 !important;
  height: 100% !important;
  width: calc(100vw / 8) !important;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: #ffe818 !important;
  justify-content: end !important;
  &::after {
    margin-right: 10px;
  }
}

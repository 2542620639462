.info-container {
  h1,
  h3,
  p {
    color: white;
  }
}

.address {
  color: white;
  margin: 0px;
}

.ticket-box {
  color: white;
  span {
    color: white;
  }
}

.event-detail-container {
  margin-bottom: 64px;
}

.ant-table {
  background-color: transparent;
  .ant-table-thead > tr > th,
  & {
    background-color: transparent;
    color: white;
  }
  .ant-table-cell {
    background-color: transparent;
    color: white;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent;
  }
}

.hayfest-booking {
  label {
    color: #ffe818;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }
  .customer-info {
    color: #ffe818;
    font-size: 16px;
    font-family: "Inconsolata";
    text-transform: uppercase;
    font-weight: bold;
  }
  .next-btn {
    background-color: #ffe818;
    color: black;
    border: none !important;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .price-title {
    font-family: "Inconsolata";
    text-transform: uppercase;
    font-weight: bold;
  }
  .dvd {
    width: 100%;
    height: 3px;
    background-color: #ffe818;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .quantity-control-input {
    border: none !important;
    width: 100%;
  }
  .quantity-btn {
    min-width: 32px !important;
    min-height: 32px !important;
    width: 32px !important;
    height: 32px !important;
    padding: 0px;
    background-color: black;
    border: none;
    color: white;
    font-size: 32px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4px;
    }
  }
  .quantity-control {
    display: flex;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.1);
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.flex-hcenter {
  display: flex !important;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
}

$sizes: 0, 0.1, 0.25, 0.5, 1, 2, 3, 4, 5;

@each $size in $sizes {
  .m-#{$size} {
    margin: #{$size}rem;
  }
  .mt-#{$size} {
    margin-top: #{$size}rem;
  }
  .mb-#{$size} {
    margin-bottom: #{$size}rem;
  }
  .ml-#{$size} {
    margin-left: #{$size}rem !important;
  }
  .mr-#{$size} {
    margin-right: #{$size}rem;
  }
  .my-#{$size} {
    margin-top: #{$size}rem;
    margin-bottom: #{$size}rem;
  }
  .mx-#{$size} {
    margin-left: #{$size}rem;
    margin-right: #{$size}rem;
  }

  .p-#{$size} {
    padding: #{$size}rem;
  }
  .px-#{$size} {
    padding-left: #{$size}rem !important;
    padding-right: #{$size}rem !important;
  }
  .py-#{$size} {
    padding-top: #{$size}rem;
    padding-bottom: #{$size}rem;
  }
  .pt-#{$size} {
    padding-top: #{$size}rem;
  }
  .pr-#{$size} {
    padding-right: #{$size}rem;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}rem;
  }
  .pl-#{$size} {
    padding-left: #{$size}rem;
  }
}

$fontWeights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@each $weight in $fontWeights {
  .fw-#{$weight} {
    font-weight: #{$weight};
  }
}

.bold {
  font-weight: bold;
}

.text {
  color: white;
}

.w-100 {
  width: 100%;
}
